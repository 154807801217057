import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import { RiTelegramLine } from "react-icons/ri";
export default function SocialMedia() {
  const telegramLink1 = "https://t.me/IRONMANONLINETOSSBOOK";
  const telegramLink2 = "https://t.me/IRONMANFAMILY";
  const whatsappLink = "https://wa.me/+918484848422";
  return (
    <div className=" w-full">
      <hr className="w-full h-0.5 mx-auto my-2 rounded  dark:bg-red-300" />
      <p className="text-lg font-medium mb-2 text-center">
        Join our social media channel
      </p>
      <div className="flex justify-center space-x-4">
        <a
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
          className=" bg-green-500 text-white p-3  rounded-full transition ease-in-out delay-150   hover:bg-green-600 duration-300 "
        >
          <BsWhatsapp className="text-lg font-bold" />
        </a>
        <a
          href={telegramLink1}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-blue-500 text-white p-2 rounded-full transition ease-in-out delay-150   hover:bg-blue-600 duration-300"
        >
          <RiTelegramLine className="text-2xl" />
        </a>
        <a
          href={telegramLink2}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-blue-500 text-white p-2 rounded-full transition ease-in-out delay-150   hover:bg-blue-600 duration-300"
        >
          <RiTelegramLine className="text-2xl" />
        </a>
      </div>
    </div>
  );
}
