import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { axisoReqWithAuthToken } from "../../Utils";
import { toast } from "react-toastify";

export default function CreateUserComponent() {
  const DEFAULT_PASSWORD = process.env.REACT_APP_DEFAULT_PASSWORD;

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const nameRef = useRef("");
  const userNameref = useRef("");
  const userTypeRef = useRef("");

  const navigate = useNavigate();

  const userType = localStorage.getItem("userType");

  const userTypes = ["client"];
  if(userType==="admin") {
    userTypes.push("admin");
    userTypes.push("employee");
  }

  const formHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formJson = Object.fromEntries(formData.entries());
    const userType = formJson.userType;

    if (!name.trim()) {
      toast.error("Please Enter Name!", {
        className: "toast-message",
      });
      nameRef.current.focus();
      return;
    }

    if (!username) {
      toast.error("Please Enter Username!", {
        className: "toast-message",
      });
      userNameref.current.focus();
      return;
    }

    if (!userType) {
      toast.error("Please Select User Type", {
        className: "toast-message",
      });
      userTypeRef.current.focus();
      return;
    }

    try {
      setIsLoading(true);
      const {data} = await axisoReqWithAuthToken.post("/admin/createUser", {userName: username, name: name.trim(), password: DEFAULT_PASSWORD, userType});
      toast.success(data.message, { className: "toast-message" });
      
      setName("");
      setUsername("");
      userTypeRef.current.value = "default";
      setIsLoading(false);
    } catch(error) {
      setIsLoading(false);
      toast.error(error.response.data.message, { className: "toast-message" });
      if(error.response.status===401) {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  return (
    <div className="flex align-center justify-center">
      <form
        onSubmit={formHandler}
        className="mt-8 max-sm:mt-0 max-sm:mb-14 pt-3 pl-8 pr-8 pb-6 flex flex-col align-center justify-center gap-1 w-[50vw] max-sm:w-[80vw] h-fit bg-white rounded-2xl shadow-[-2px_2px_10px_rgba(0,0,0,0.3)]"
      >
        <h1 className="text-xl font-semibold flex justify-center">
          Create User
        </h1>
        <div>
          <label htmlFor="name" className="block text-700 font-semibold mb-1">
            Name
          </label>
          <input
            type="text"
            placeholder="Name"
            required
            ref={nameRef}
            value={name}
            className="w-full px-2 py-1  mb-2 rounded-md ring-2 ring-red-500 focus:outline-none"
            id="name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="">
          <label
            htmlFor="username"
            className="block text-700 font-semibold mb-1"
          >
            Username
          </label>
          <input
            type="text"
            placeholder="Username"
            required
            ref={userNameref}
            value={username}
            className="w-full px-2 py-1  mb-2 rounded-md ring-2 ring-red-500 focus:outline-none "
            id="username"
            onChange={(e) => setUsername(e.target.value.trim())}
          />
        </div>
        <div className="">
          <label
            htmlFor="password"
            className="block text-700 font-semibold  mb-1 "
          >
            Password
          </label>
          <div className="relative mb-2">
            <input
              type="text"
              placeholder="Password"
              required
              className="w-full px-2 py-1 rounded-md ring-2 ring-red-500 mb-2 focus:outline-none bg-slate-300"
              defaultValue={DEFAULT_PASSWORD}
              id="password"
              readOnly="readonly"
            />
          </div>
        </div>
        <div className="">
          <label
            htmlFor="userType"
            className="block text-base font-medium text-gray-900"
          >
            Select User Type
          </label>
          <select
            name="userType"
            defaultValue={"default"}
            ref={userTypeRef}
            className="w-full p-1 border-2 border-red-500 border-solid rounded-md focus:outline-red-400 max-lg:w-full"
          >
            <option disabled value="default">
              {"-- select an option --"}
            </option>
            {userTypes.map((team, idx) => (
              <option key={idx} value={team} className="focus:bg-red-500">
                {team}
              </option>
            ))}
          </select>
        </div>
        <button
          type="submit"
          className={"mt-6 hover:bg-red-500 text-red-700 text-center font-semibold hover:text-white py-1 px-5 border border-red-500 hover:border-transparent rounded transition-btn " + `${isLoading && "pointer-events-none"}`}
        >
          {isLoading ? "Loading..." : "Submit"}
        </button>
      </form>
    </div>
  );
}
