import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
    AiFillHome,
    AiTwotoneSetting,
    AiOutlineSchedule,
} from "react-icons/ai";
import { VscBook } from "react-icons/vsc";
import { FaUserPlus, FaListAlt, FaUsersSlash } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { BiSolidMobileVibration, BiSubdirectoryRight } from "react-icons/bi";
import { MdAnalytics } from "react-icons/md";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { toast } from "react-toastify";
import { userTypes, delay, axisoReqWithAuthToken } from "../../Utils";

export default function AdminSideBar({
  isSmallScreen,
  showSidebar,
  showSidebarHandler,
  username,
}) {
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPassbookOpen, setisPassbookOpen] = useState(false);
  const [bigScreenHeight, setBigScreenHeight] = useState("110vh");
  const [smallScreenHeight, setSmallScreenHeight] = useState("93vh");
  const navigate = useNavigate();

  const logoutHandler = async () => {
    try {
      setIsLoading(true);
      await axisoReqWithAuthToken.post("/logout");
      localStorage.clear();
      toast.success("Logout Successful!", { className: "toast-message" });
      await delay(500);
      setIsLoading(false);
      navigate("/login");
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message, { className: "toast-message" });
      if (error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  const isAdmin =
    localStorage.getItem("userType") === userTypes.admin ? true : false;
    const isEmployee = localStorage.getItem("userType") === userTypes.employee ? true : false;
  const navLinksListHandler = (event) => {
    const nearestLi = event.target.closest("li");
    if (!nearestLi) return;
  };

  const navLinksList = (
      <ul
          onClick={navLinksListHandler}
          className="flex flex-col gap-4 max-sm:gap-2 px-2"
      >
          <li className="border-[1px] border-slate-300 rounded-md overflow-hidden">
              <NavLink
                  end
                  to="/admin"
                  className="pl-4 py-3 max-sm:py-2 hover:bg-red-500 hover:text-white transition-li"
                  onClick={showSidebarHandler}
              >
                  <AiFillHome className="max-sm:text-sm" />
                  <span className="font-semibold max-sm:text-sm">Home</span>
              </NavLink>
          </li>
          <li className="border-[1px] border-slate-300 rounded-md overflow-hidden">
              <NavLink
                  to="/admin/createUser"
                  className="pl-4 py-3 max-sm:py-2 hover:bg-red-500 hover:text-white transition-li"
                  onClick={showSidebarHandler}
              >
                  <FaUserPlus className="max-sm:text-sm" />
                  <span className="font-semibold max-sm:text-sm">
                      Create User
                  </span>
              </NavLink>
          </li>
          <li className="border-[1px] border-slate-300 rounded-md overflow-hidden">
              <NavLink
                  to="/admin/createBet"
                  className="pl-4 py-3 max-sm:py-2 hover:bg-red-500 hover:text-white transition-li"
                  onClick={showSidebarHandler}
              >
                  <BiSolidMobileVibration className="max-sm:text-sm" />
                  <span className="font-semibold max-sm:text-sm">
                      Create Bet
                  </span>
              </NavLink>
          </li>
          <li className="border-[1px] border-slate-300 rounded-md overflow-hidden">
              <div
                  className="p-2 pl-4 hover:bg-red-500 hover:text-white cursor-pointer transition-li"
                  onClick={() => setIsScheduleOpen((prev) => !prev)}
              >
                  <AiOutlineSchedule className="max-sm:text-sm" />
                  <div className="flex gap-1 items-center">
                      <span className="font-semibold max-sm:text-sm">
                          Schedule
                      </span>
                      {isScheduleOpen ? (
                          <IoIosArrowDown />
                      ) : (
                          <IoIosArrowForward />
                      )}
                  </div>
              </div>
          </li>
          {isScheduleOpen && (
              <>
                  <li
                      className={
                          "border-[1px] border-slate-300 rounded-md overflow-hidden passbook " +
                          `${isScheduleOpen ? "transition-passbook" : ""}`
                      }
                  >
                      <NavLink
                          to="/kabaddi"
                          className="p-2 pl-8 hover:bg-red-500 hover:text-white transition-li"
                          onClick={showSidebarHandler}
                      >
                          <BiSubdirectoryRight className="hover:text-white max-sm:text-sm" />
                          <span className="font-semibold max-sm:text-sm">
                              Kabaddi
                          </span>
                      </NavLink>
                  </li>
                  <li
                      className={
                          "border-[1px] border-slate-300 rounded-md overflow-hidden passbook " +
                          `${isScheduleOpen ? "transition-passbook" : ""}`
                      }
                  >
                      <NavLink
                          to="/cricket"
                          className="p-2 pl-8 hover:bg-red-500 hover:text-white transition-li"
                          onClick={showSidebarHandler}
                      >
                          <BiSubdirectoryRight className="hover:text-white max-sm:text-sm" />
                          <span className="font-semibold max-sm:text-sm">
                              Cricket
                          </span>
                      </NavLink>
                  </li>
              </>
          )}
        <li className="border-[1px] border-slate-300 rounded-md overflow-hidden">
            <NavLink
                to="/admin/userList"
                className="pl-4 py-3 max-sm:py-2 hover:bg-red-500 hover:text-white transition-li"
                onClick={showSidebarHandler}
            >
                <FaListAlt className="max-sm:text-sm" />
                <span className="font-semibold max-sm:text-sm">
                    User List
                </span>
            </NavLink>
        </li>
        <li className="border-[1px] border-slate-300 rounded-md overflow-hidden">
            <NavLink
                to= "/admin/blockedList"
                className="pl-4 py-3 max-sm:py-2 hover:bg-red-500 hover:text-white transition-li"
                onClick={showSidebarHandler}
            >
                <FaUsersSlash className="max-sm:text-sm" />
                <span className="font-semibold max-sm:text-sm">
                    Blocked List
                </span>
            </NavLink>
        </li>
        {isEmployee && (    
        <li className="border-[1px] border-slate-300 rounded-md overflow-hidden">
              <div
                  className="p-2 pl-4 hover:bg-red-500 hover:text-white cursor-pointer transition-li"
                  onClick={() => setisPassbookOpen((prev) => !prev)}
              >
                  <VscBook className="max-sm:text-sm" />
                  <div className="flex gap-1 items-center">
                      <span className="font-semibold max-sm:text-sm">
                          Passbook
                      </span>
                      {isPassbookOpen ? (
                          <IoIosArrowDown />
                      ) : (
                          <IoIosArrowForward />
                      )}
                  </div>
              </div>
          </li>
        )}
          {isPassbookOpen && (
              <>
                  <li
                      className={
                          "border-[1px] border-slate-300 rounded-md overflow-hidden passbook " +
                          `${isPassbookOpen ? "transition-passbook" : ""}`
                      }
                  >
                      <NavLink
                          to="/debit"
                          className="p-2 pl-8 hover:bg-red-500 hover:text-white transition-li"
                          onClick={showSidebarHandler}
                      >
                          <BiSubdirectoryRight className="hover:text-white max-sm:text-sm" />
                          <span className="font-semibold max-sm:text-sm">
                              Debited
                          </span>
                      </NavLink>
                  </li>
                  <li
                      className={
                          "border-[1px] border-slate-300 rounded-md overflow-hidden passbook " +
                          `${isPassbookOpen ? "transition-passbook" : ""}`
                      }
                  >
                      <NavLink
                          to="/credit"
                          className="p-2 pl-8 hover:bg-red-500 hover:text-white transition-li"
                          onClick={showSidebarHandler}
                      >
                          <BiSubdirectoryRight className="hover:text-white max-sm:text-sm" />
                          <span className="font-semibold max-sm:text-sm">
                              Credited
                          </span>
                      </NavLink>
                  </li>
              </>
          )}
        <li className="border-[1px] border-slate-300 rounded-md overflow-hidden">
            <NavLink
                to="/admin/marketAnalysis"
                className="pl-4 py-3 max-sm:py-2 hover:bg-red-500 hover:text-white transition-li"
                onClick={showSidebarHandler}
            >
                <MdAnalytics className="max-sm:text-sm" />
                <span className="font-semibold max-sm:text-sm">
                    Market Analysis
                </span>
            </NavLink>
        </li>
          <li className="border-[1px] border-slate-300 rounded-md overflow-hidden">
              <NavLink
                  to="/changePassword"
                  state={{ username: username }}
                  className="pl-4 py-3 max-sm:py-2 hover:bg-red-500 hover:text-white transition-li"
                  onClick={showSidebarHandler}
              >
                  <AiTwotoneSetting className="hover:text-white max-sm:text-sm" />
                  <span className="font-semibold max-sm:text-sm">
                      Change Password
                  </span>
              </NavLink>
          </li>
          <li className="block w-full border-[1px] border-slate-300 rounded-md overflow-hidden">
            <div
              className={
                "p-2 pl-4 hover:bg-red-500 hover:text-white transition-li cursor-pointer " +
                `${isLoading && "pointer-events-none"}`
              }
              onClick={logoutHandler}
            >
              <FiLogOut className="hover:text-white max-sm:text-sm" />
              <span className="font-semibold max-sm:text-sm">Logout</span>
            </div>
          </li>
      </ul>
  );

  useEffect(() => {
    if (isAdmin) {
        if (isScheduleOpen) {
            setBigScreenHeight("130vh")
            setSmallScreenHeight("102vh")
        } else {
            setBigScreenHeight("110vh")
            setSmallScreenHeight("93vh")
        }
    } else {
        if (isScheduleOpen && isPassbookOpen) {
            setBigScreenHeight("160vh")
            setSmallScreenHeight("121vh")
        } else if (!isScheduleOpen && !isPassbookOpen) {
            setBigScreenHeight("120vh")
            setSmallScreenHeight("93vh")
        } else {
            setBigScreenHeight("140vh")
            setSmallScreenHeight("107vh")
        }
    }
  }, [isScheduleOpen, isPassbookOpen])

  if (!isSmallScreen)
    return (
      <div className={`min-h-[${bigScreenHeight}] top-[${isEmployee ? "100px" : "2vh"}] w-full z-20 pt-8 overflow-hidden bg-red-50`}>
        {navLinksList}
      </div>
    );

  if (isSmallScreen)
    return (
      <div
        className={`z-20 mt-10 pt-12 bg-red-50 sidebar-admin min-h-[${smallScreenHeight}] 
        top-[${isEmployee ? "5vh" : "2vh"}] ${showSidebar ? "sidebar-transition" : ""}`}
      >
        {navLinksList}
      </div>
    );
}
