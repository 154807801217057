import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { VscBook } from "react-icons/vsc";
import { NavLink } from "react-router-dom";
import { BiTransfer, BiSubdirectoryRight } from "react-icons/bi";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { AiFillHome, AiTwotoneSetting, AiOutlineSchedule } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { toast } from "react-toastify";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { delay, axisoReqWithAuthToken } from "../Utils";

export default function SideBar({
  isSmallScreen,
  showSidebar,
  showSidebarHandler,
  username,
}) {
  const [isPassbookOpen, setisPassbookOpen] = useState(false);
  const [isScheduleOpen, setIsScheduleOpen] = useState(false);
  const [bigScreenHeight, setBigScreenHeight] = useState("90vh");
  const [isLoading, setIsLoading] = useState(false);
  const whatsappLink = "https://wa.me/+918484848422";
  const navigate = useNavigate();

  const navLinksListHandler = (event) => {
    const nearestLi = event.target.closest("li");
    if (!nearestLi) return;
  };

  const logoutHandler = async () => {
    try {
      setIsLoading(true);
      await axisoReqWithAuthToken.post("/logout");
      localStorage.clear();
      toast.success("Logout Successful!", { className: "toast-message" });
      await delay(500);
      setIsLoading(false);
      navigate("/login");
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message, { className: "toast-message" });
      if (error.response.status === 401) {
        localStorage.clear();
        navigate("/login");
      }
    }
  };

  const navLinksList = (
      <ul
          onClick={navLinksListHandler}
          className="flex flex-col gap-4 max-sm:gap-2 px-2"
      >
          <li className="border-[1px] border-slate-300 rounded-md overflow-hidden">
              <NavLink
                  to="/"
                  className="p-2 pl-4 hover:bg-red-500 hover:text-white transition-li"
                  onClick={showSidebarHandler}
              >
                  <AiFillHome className="max-sm:text-sm" />
                  <span className="font-semibold max-sm:text-sm">Home</span>
              </NavLink>
          </li>
          <li className="border-[1px] border-slate-300 rounded-md overflow-hidden">
              <NavLink
                  to="/tossTransaction"
                  className="p-2 pl-4 hover:bg-red-500 hover:text-white transition-li"
                  onClick={showSidebarHandler}
              >
                  <BiTransfer className="hover:text-white max-sm:text-sm" />
                  <span className="font-semibold max-sm:text-sm">
                      Toss Transaction
                  </span>
              </NavLink>
          </li>
          <li className="border-[1px] border-slate-300 rounded-md overflow-hidden">
              <div
                  className="p-2 pl-4 hover:bg-red-500 hover:text-white cursor-pointer transition-li"
                  onClick={() => {
                    setisPassbookOpen((prev) => !prev)
                    if (!isPassbookOpen && isScheduleOpen) {
                        setBigScreenHeight("125vh")
                    } else if ((!isPassbookOpen && !isScheduleOpen) || (isPassbookOpen && isScheduleOpen)) {
                        setBigScreenHeight("105vh")
                    } else {
                        setBigScreenHeight("92vh")
                    }
                }}
              >
                  <VscBook className="max-sm:text-sm" />
                  <div className="flex gap-1 items-center">
                      <span className="font-semibold max-sm:text-sm">
                          Passbook
                      </span>
                      {isPassbookOpen ? (
                          <IoIosArrowDown />
                      ) : (
                          <IoIosArrowForward />
                      )}
                  </div>
              </div>
          </li>
          {isPassbookOpen && (
              <>
                  <li
                      className={
                          "border-[1px] border-slate-300 rounded-md overflow-hidden passbook " +
                          `${isPassbookOpen ? "transition-passbook" : ""}`
                      }
                  >
                      <NavLink
                          to="/debit"
                          className="p-2 pl-8 hover:bg-red-500 hover:text-white transition-li"
                          onClick={showSidebarHandler}
                      >
                          <BiSubdirectoryRight className="hover:text-white max-sm:text-sm" />
                          <span className="font-semibold max-sm:text-sm">
                              Debited
                          </span>
                      </NavLink>
                  </li>
                  <li
                      className={
                          "border-[1px] border-slate-300 rounded-md overflow-hidden passbook " +
                          `${isPassbookOpen ? "transition-passbook" : ""}`
                      }
                  >
                      <NavLink
                          to="/credit"
                          className="p-2 pl-8 hover:bg-red-500 hover:text-white transition-li"
                          onClick={showSidebarHandler}
                      >
                          <BiSubdirectoryRight className="hover:text-white max-sm:text-sm" />
                          <span className="font-semibold max-sm:text-sm">
                              Credited
                          </span>
                      </NavLink>
                  </li>
              </>
          )}
          <li className="border-[1px] border-slate-300 rounded-md overflow-hidden">
              <div
                  className="p-2 pl-4 hover:bg-red-500 hover:text-white cursor-pointer transition-li"
                  onClick={() => {
                    setIsScheduleOpen((prev) => !prev)
                    if (isPassbookOpen && !isScheduleOpen) {
                        setBigScreenHeight("125vh")
                    } else if ((!isPassbookOpen && !isScheduleOpen) || (isPassbookOpen && isScheduleOpen)) {
                        setBigScreenHeight("105vh")
                    } else {
                        setBigScreenHeight("92vh")
                    }
                }}
              >
                  <AiOutlineSchedule className="max-sm:text-sm" />
                  <div className="flex gap-1 items-center">
                      <span className="font-semibold max-sm:text-sm">
                          Schedule
                      </span>
                      {isScheduleOpen ? (
                          <IoIosArrowDown />
                      ) : (
                          <IoIosArrowForward />
                      )}
                  </div>
              </div>
          </li>
          {isScheduleOpen && (
              <>
                  <li
                      className={
                          "border-[1px] border-slate-300 rounded-md overflow-hidden passbook " +
                          `${isScheduleOpen ? "transition-passbook" : ""}`
                      }
                  >
                      <NavLink
                          to="/kabaddi"
                          className="p-2 pl-8 hover:bg-red-500 hover:text-white transition-li"
                          onClick={showSidebarHandler}
                      >
                          <BiSubdirectoryRight className="hover:text-white max-sm:text-sm" />
                          <span className="font-semibold max-sm:text-sm">
                              Kabaddi
                          </span>
                      </NavLink>
                  </li>
                  <li
                      className={
                          "border-[1px] border-slate-300 rounded-md overflow-hidden passbook " +
                          `${isScheduleOpen ? "transition-passbook" : ""}`
                      }
                  >
                      <NavLink
                          to="/cricket"
                          className="p-2 pl-8 hover:bg-red-500 hover:text-white transition-li"
                          onClick={showSidebarHandler}
                      >
                          <BiSubdirectoryRight className="hover:text-white max-sm:text-sm" />
                          <span className="font-semibold max-sm:text-sm">
                              Cricket
                          </span>
                      </NavLink>
                  </li>
              </>
          )}
                    <li className="border-[1px] border-slate-300 rounded-md overflow-hidden">
            <a
                href={whatsappLink}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 pl-4 hover:bg-red-500 hover:text-white transition-li"
            >
                <FaMoneyCheckDollar className="max-sm:text-sm" />
                <span className="font-semibold max-sm:text-sm">Add / Withdraw Money</span>
            </a>
          </li>
          <li className="border-[1px] border-slate-300 rounded-md overflow-hidden">
              <NavLink
                  to="/changePassword"
                  state={{ username: username }}
                  className="pl-4 pb-3 pt-3 hover:bg-red-500 hover:text-white transition-li max-sm:text-sm"
                  onClick={showSidebarHandler}
              >
                  <AiTwotoneSetting className="hover:text-white max-sm:text-sm" />
                  <span className="font-semibold">Change Password</span>
              </NavLink>
          </li>
            <li className="block w-full border-[1px] border-slate-300 rounded-md overflow-hidden">
                <div
                    className={`p-2 pl-4 hover:bg-red-500 hover:text-white transition-li cursor-pointer ${
                    isLoading && "pointer-events-none"
                    }`}
                    onClick={logoutHandler}
                >
                    <FiLogOut className="hover:text-white max-sm:text-sm" />
                    <span className="font-semibold max-sm:text-sm">Logout</span>
                </div>
            </li>
      </ul>
  );

return (
    <div
    className={`z-20 bg-red-50 ${
        isSmallScreen
        ? `mt-6 pt-20 sidebar min-h-[${isPassbookOpen && isScheduleOpen ? "110vh" : "93vh"}] ${showSidebar ? "sidebar-transition" : ""}`
        : `min-h-[${bigScreenHeight}] w-full pt-14`
    }`}
    >
        {navLinksList}
    </div>
);
}
